import { useAppState } from '../../hooks/useAppState';

import siteMapIcon from '../../assets/images/site-map.png';
import siteDetailIcon from '../../assets/images/site-details.svg';
import { Form, FormGroup, Label } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { WrappedInput } from '../../components/WrappedInput';
import { mergeObjs } from '../../utils/ObjectUtils';
import GenericStepForm from './generic-step-form';
import { WrappedNumberInput } from '../../components/WrappedNumberInput';
import RangeSliderWithTooltip from '../../components/RangeSliderWithTooltip';
import { useEffect, useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
function SiteDetailStep({ step, setStep }) {
  const [stepValues, setStepValues] = useAppState();

  const { register, setError, handleSubmit, formState, watch, reset, control, trigger, setValue } = useForm({
    defaultValues: mergeObjs(stepValues, {
      isIntersection: 'yes',
      isSitePlanProv: 'yes',
      isFunRetailUnit: 'no',
      areaType: 'Sqft',
      foodSalesPerMonth: 0,
      carWashSalesPerMonth: 0,
      cStoreSalePerMonth: 0,
      avgCurbCutSize: 10,
      priFrontFootage: 10,
      secFrontFootage: 10,
    }),
    mode: 'onSubmit',
  });
  const { errors } = formState;

  const isFunRetailUnitValue = watch('isFunRetailUnit');

  const areaType = watch('areaType');

  useEffect(() => {
    if (isFunRetailUnitValue == 'no') {
      setValue('cStoreSalePerMonth', 0);
      setValue('foodSalesPerMonth', 0);
      setValue('carWashSalesPerMonth', 0);
    }
  }, [isFunRetailUnitValue]);

  return (
    <GenericStepForm step={step} setStep={setStep} submitHandler={handleSubmit}>
      <div className="section-header">
        <img src={siteDetailIcon} alt={''} />
        <h2 className="section-heading">Site Details</h2>
      </div>
      <div className="row">
        <div className="col-lg-7">
          <div className="mb-3">
            <p>Site Area Type</p>

            <div className="mb-3">
              <FormGroup check inline>
                <Label check>
                  <WrappedInput
                    {...register('areaType')}
                    value={'Sqft'}
                    onChange={(e) => {
                      setValue('areaType', 'Sqft');
                      setValue('totalAreaSqFt', '');
                    }}
                    type="radio"
                    name={'areaType'}
                  />
                  <label>Sqft</label>
                </Label>
              </FormGroup>
              <FormGroup check inline>
                <Label check>
                  <WrappedInput
                    {...register('areaType')}
                    onChange={(e) => {
                      setValue('areaType', 'Acre');
                      setValue('totalAreaSqFt', '');
                    }}
                    value={'Acre'}
                    type="radio"
                    name={'areaType'}
                  />
                  <label>Acre</label>
                </Label>
              </FormGroup>
            </div>

            <WrappedNumberInput
              errors={errors}
              registerhandler={register}
              min={1}
              max={500000}
              required={true}
              name={'totalAreaSqFt'}
              placeholder={areaType  == 'Acre' ? 'Site Area in Acre' : 'Site Area in sq. ft'}
              onFocus={async () => {
                trigger('totalAreaSqFt');
              }}
              onBlur={async () => {
                trigger('totalAreaSqFt');
              }}
            />

            <WrappedNumberInput
              errors={errors}
              registerhandler={register}
              min={1}
              max={500000}
              required={true}
              name={'estimated_purchase_per_price_sq_ft'}
              placeholder={'Est. Purchase Price Per Square Foot'}
              onFocus={async () => {
                trigger('estimated_purchase_per_price_sq_ft');
              }}
              onBlur={async () => {
                trigger('estimated_purchase_per_price_sq_ft');
              }}
            />
          </div>
          <div className="mb-4">
            <WrappedNumberInput
              errors={errors}
              registerhandler={register}
              min={1}
              max={20}
              required={true}
              name={'totalCurbCuts'}
              placeholder="How many driveway access (curb cuts)"
              onFocus={async () => {
                trigger('totalCurbCuts');
              }}
              onBlur={async () => {
                trigger('totalCurbCuts');
              }}
            />
          </div>
          <div className="mb-3">
            <label className="form-label"> Average driveway access size (estimated average 30-40 ft.)</label>
            <RangeSliderWithTooltip
              register={register}
              control={control}
              name={'avgCurbCutSize'}
              min="10"
              max="1000"
              step={10}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Road Frontage ( Primary )</label>
            <RangeSliderWithTooltip
              register={register}
              control={control}
              name={'priFrontFootage'}
              min="10"
              max="1000"
              step={10}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Road Frontage ( Secondary )</label>
            <RangeSliderWithTooltip
              register={register}
              control={control}
              name={'secFrontFootage'}
              min="10"
              max="1000"
              step={10}
            />
          </div>
        </div>
        <div className="col-lg-5 seciton-flex">
          <img src={siteMapIcon} alt={''} />
        </div>
      </div>
      <hr />
      <div className="row">
        {stepValues.devType == 'existing' && (
          <div className="col-lg-12">
            <div className="mb-3">
              <label className="form-label d-block">Is your location a functioning retail unit ?</label>
              <FormGroup check inline>
                <Label check>
                  <WrappedInput {...register('isFunRetailUnit')} value={'yes'} type="radio" name={'isFunRetailUnit'} />
                  <label>Yes</label>
                </Label>
              </FormGroup>
              <FormGroup check inline>
                <Label check>
                  <WrappedInput {...register('isFunRetailUnit')} value={'no'} type="radio" name={'isFunRetailUnit'} />
                  <label>No</label>
                </Label>
              </FormGroup>
            </div>
          </div>
        )}

        <div className="col-lg-4">
          <div className="mb-3">
            <WrappedNumberInput
              errors={errors}
              registerhandler={register}
              min={0}
              max={50000000}
              disabled={isFunRetailUnitValue === 'no'}
              name={'cStoreSalePerMonth'}
              placeholder="C-Store Sales (per month)"
            />
          </div>
        </div>

        {stepValues?.facType == 'commercial' && (
          <div className="col-lg-4">
            <div className="mb-3">
              <WrappedNumberInput
                errors={errors}
                registerhandler={register}
                min={0}
                max={50000000}
                disabled={isFunRetailUnitValue === 'no'}
                name={'foodSalesPerMonth'}
                placeholder="Food Sales (per month)"
              />
            </div>
          </div>
        )}
        {stepValues?.facType == 'commercial' && (
          <div className="col-lg-4">
            <div className="mb-3">
              <WrappedNumberInput
                errors={errors}
                registerhandler={register}
                min={0}
                max={50000000}
                disabled={isFunRetailUnitValue === 'no'}
                name={'carWashSalesPerMonth'}
                placeholder="Car Wash Sales (per month)"
              />
            </div>
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-lg-4 mt-2">
          <div className="mb-3">
            <label className="form-label d-block">Is site at intersection ?</label>
            <FormGroup check inline>
              <Label check>
                <WrappedInput {...register('isIntersection')} value={'yes'} type="radio" name={'isIntersection'} />
                <label>Yes</label>
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Label check>
                <WrappedInput {...register('isIntersection')} value={'no'} type="radio" name={'isIntersection'} />
                <label>No</label>
              </Label>
            </FormGroup>
          </div>
        </div>
        <div className="col-lg-4 mt-2">
          <div className="mb-3">
            <label className="form-label d-block">Is Site Plan Provided ?</label>
            <FormGroup check inline>
              <Label check>
                <WrappedInput {...register('isSitePlanProv')} value={'yes'} type="radio" name={'isSitePlanProv'} />
                <label>Yes</label>
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Label check>
                <WrappedInput {...register('isSitePlanProv')} value={'no'} type="radio" name={'isSitePlanProv'} />
                <label>No</label>
              </Label>
            </FormGroup>
          </div>
        </div>
      </div>
    </GenericStepForm>
  );
}

export default SiteDetailStep;
