export function mergeObjs(obj1, obj2) {
  const merged = {};
  let keys1 = Object.keys(obj1);
  keys1.forEach((k1) => {
    merged[k1] = obj1[k1] || obj2[k1]; // replace values from 2nd object, if any
  });
  Object.keys(obj2).forEach((k2) => {
    if (!keys1.includes(k2)) merged[k2] = obj2[k2]; // add additional properties from second object, if any
  });
  return merged;
}

export let restructureCommercialData = (data) => {
  let obj = {
    name: data.reportTitle && data.reportTitle.replace(/\s/g, '_'),
    project_type: data.projType,
    development_type: data.devType,
    facility_type: data.facType,
    siteImages: data.siteImages,
    interiorSitePlans: data.interiorSitePlans,
    exteriorSitePlans: data.exteriorSitePlans,

    business_leads: data.business_leads ? data.business_leads : [],
    industrial_sites: [
      {
        report_title: data.reportTitle.trim(),
        address: data?.compAddress,
        pri_artery: data.priArtery,
        sec_artery: data.secArtery,
        state: 'TX',
        is_at_intersection: data.isIntersection,
        closest_pri_intersection: data.closestPriInt,
        quadrant_corner: data.quadrantCorner,
        site_identifiers: data.siteIdentifiers,
        directions: data.directions,
        total_area_sqft:
          data.areaType && data?.areaType == 'Acre' ? Number(data?.totalAreaSqFt * 43560) : data.totalAreaSqFt,
        estimated_purchase_per_price_sq_ft: data?.estimated_purchase_per_price_sq_ft,
        // estimated_purchase_per_price_sq_ft: data.areaType && data?.areaType[0]?.label == 'Acre' ? Number(data?.estimated_purchase_per_price_sq_ft * 43560) : data?.estimated_purchase_per_price_sq_ft,
        is_site_plan_provided: data.isSitePlanProv,
        curb_cuts_pri: data.totalCurbCuts,
        curb_cuts_sec: data.avgCurbCutSize,
        frontage_footage_pri: data.priFrontFootage,
        frontage_footage_sec: data.secFrontFootage,
        is_functioning_retail: data.isFunRetailUnit,
        lat: data.lat,
        lng: data.lon,
      },
    ],
    strategies: [
      {
        num_mpds: data.numMPD,
        num_auto_diesel_hoses: data.numAutoDieselHose,
        storeType: data.storeType,
        store_size: data.storeSize,
        gas_brand_consideration: data.gasBrandConsideration,
        att_qsrSize: data.qsrSize ? data.qsrSize :0,
        att_qsr_brand: data.qsrBrand,
        qsr_private_deli_brand: data.qsrPrivateDeliBrand,
        qsr_private_deli_size: data.qsrPrivateDeliSize ?  data.qsrPrivateDeliSize:0,
        no_of_retail_tenant: data.noOfRetailTenant,
        retail_tenant_total_size: data.retailTenantTotalSize,
        car_wash_type: data.carWashType,
        numCarWash: data.numCarWash,
        num_cooler_doors: data.f_cooler_door,
        num_freezers_doors: data.f_freezer_door,
        is_beer_cave: data.f_beer_cave,
        is_24_hour_ops: data.f_24H_ops,
      },
    ],

    retail_unit: [
      {
        cstoresalespermonth: data.cStoreSalePerMonth ?data.cStoreSalePerMonth :0,
        foodsalespermonth: data.foodSalesPerMonth ? data.foodSalesPerMonth:0,
        carwashpermonth: data.carWashSalesPerMonth ? data.carWashSalesPerMonth:0,
        // gasolinegallonspermonth: 0,
        // otherfuelgallonspermonth: 0,
      },
    ],
    devStrategyAddon: [],
    addons: [],

    //  devStrategyAddon: [{ name: 'abc' }, { name: 'def' }],
    //  addons: [{ name: 'abc' }, { name: 'def' }],
  };

  return obj;
};

export let restructureResidentailData = (data) => {
  let { projType, facType, devType, unitType } = data;

  const __other_features = data.other_features ? data?.other_features.join(',') : '';
  const __neighborhood_conditions = data.neighborhood_conditions ? data?.neighborhood_conditions.join(',') : '';

  let obj = {
    name: data.reportTitle,
    project_type: projType,
    development_type: devType,
    facility_type: facType,

    // uncomment for testing
    residential_sites: [
      {
        report_title: data.reportTitle,
        state: 'TX',
        total_area_sqft:
          data.areaType && data?.areaType[0]?.label == 'Acre'
            ? Number(data?.totalAreaSqFt * 43560)
            : data.totalAreaSqFt,

        address: data.compAddress,
        lat: data.lat,
        lng: data.lon,
        unit_type: unitType,
        other_features: __other_features,
        neighborhood_conditions: __neighborhood_conditions,
        num_studios: data?.num_studios ? data.num_studios : 0,
        studio_avg_rent: data?.studio_avg_rent ? data.studio_avg_rent : 0,
        studio_total_sqft: data?.studio_total_sqft ? data.studio_total_sqft : 0,
        num_one_bedroom: data?.num_one_bedroom ? data.num_one_bedroom : 0,
        one_bedroom_avg_rent: data?.one_bedroom_avg_rent ? data.one_bedroom_avg_rent : 0,
        one_bedroom_total_sqft: data?.one_bedroom_total_sqft ? data.one_bedroom_total_sqft : 0,
        num_two_bedroom: data?.num_two_bedroom ? data.num_two_bedroom : 0,
        two_bedroom_avg_rent: data?.two_bedroom_avg_rent ? data.two_bedroom_avg_rent : 0,
        two_bedroom_total_sqft: data?.two_bedroom_total_sqft ? data.two_bedroom_total_sqft : 0,
        num_three_bedroom: data?.num_three_bedroom ? data.num_three_bedroom : 0,
        three_bedroom_avg_rent: data?.three_bedroom_avg_rent ? data.three_bedroom_avg_rent : 0,
        three_bedroom_total_sqft: data?.three_bedroom_total_sqft ? data.three_bedroom_total_sqft : 0,
        building_quality: data.building_quality,
        roof_type: data.roof_type,
        kitchens_quality: data.kitchens_quality,
        bathroom_quality: data?.bathroom_quality,
        airconditioning_type: data?.airconditioning_type,
        exterior_type: data?.exterior_type,
        parking_type: data.parking_type,
        overnight_parking_available: data.overnight_parking_available,
        laundry_income: data.laundry_income ? data.laundry_income : 0,
        parking_income: data.parking_income ? data.parking_income : 0,
        storage_income: data.storage_income ? data.storage_income : 0,
        antennas_income: data.antennas_income ? data.antennas_income : 0,
        billboards_income: data.billboards_income ? data.billboards_income : 0,
        elec_bill_by_tenants: data.elec_bill_by_tenants,
        heat_bill_by_tenants: data.heat_bill_by_tenants,
        real_estate_tax: data.residential_sites ? data.residential_sites : 0,
        property_insurance: data.property_insurance ? data.property_insurance : 0,
        heat_bill: data.heat_bill ? data.heat_bill : 0,
        electric_bill: data.electric_bill ? data.electric_bill : 0,
        water_and_sewer_bill: data.water_and_sewer_bill ? data.water_and_sewer_bill : 0,
        maintenance_and_repairs: data.maintenance_and_repairs ? data.maintenance_and_repairs : 0,
        management_cost: data.management_cost ? data.management_cost : 0,
        administrative_cost: data.administrative_cost ? data.administrative_cost : 0,
      },
    ],

    // comment for testing
    // without static 0 if value is undefined or null
    // residential_sites: [
    //   {
    //     report_title: data.reportTitle,
    //     state: 'TX',
    //     total_area_sqft: data.totalAreaSqFt  ,
    //     address: data.compAddress,
    //     lat: data.lat,
    //     lng: data.lon,
    //     unit_type: unitType,
    //     other_features: __other_features,
    //     neighborhood_conditions: __neighborhood_conditions,
    //     num_studios: data?.num_studios,
    //     studio_avg_rent: data?.studio_avg_rent,
    //     studio_total_sqft: data?.studio_total_sqft,
    //     num_one_bedroom: data?.num_one_bedroom,
    //     one_bedroom_avg_rent: data?.one_bedroom_avg_rent,
    //     one_bedroom_total_sqft: data?.one_bedroom_total_sqft,
    //     num_two_bedroom: data?.num_two_bedroom,
    //     two_bedroom_avg_rent: data?.two_bedroom_avg_rent,
    //     two_bedroom_total_sqft: data?.two_bedroom_total_sqft,
    //     num_three_bedroom: data?.num_three_bedroom,
    //     three_bedroom_avg_rent: data?.three_bedroom_avg_rent,
    //     three_bedroom_total_sqft: data?.three_bedroom_total_sqft,
    //     building_quality: data.building_quality,
    //     roof_type: data.roof_type,
    //     kitchens_quality: data.kitchens_quality,
    //     bathroom_quality: data?.bathroom_quality,
    //     airconditioning_type: data?.airconditioning_type,
    //     exterior_type: data?.exterior_type,
    //     parking_type: data.parking_type,
    //     overnight_parking_available: data.overnight_parking_available,
    //     laundry_income: data.laundry_income,
    //     parking_income: data.parking_income,
    //     storage_income: data.storage_income,
    //     antennas_income: data.antennas_income,
    //     billboards_income: data.billboards_income,
    //     elec_bill_by_tenants: data.elec_bill_by_tenants,
    //     heat_bill_by_tenants: data.heat_bill_by_tenants,
    //     real_estate_tax: data.residential_sites,
    //     property_insurance: data.property_insurance,
    //     heat_bill: data.heat_bill,
    //     electric_bill: data.electric_bill,
    //     water_and_sewer_bill: data.water_and_sewer_bill,
    //     maintenance_and_repairs: data.maintenance_and_repairs,
    //     management_cost: data.management_cost,
    //     administrative_cost: data.administrative_cost,
    //   },
    // ],
  };

  return obj;
};

export let restructureGroceryData = (data) => {
  let obj = {
    name: data.reportTitle,
    project_type: data.projType,
    development_type: data.devType,
    facility_type: data.facType,
    business_leads: data.business_leads,
    siteImages: data.siteImages,
    interiorSitePlans: data.interiorSitePlans,
    exteriorSitePlans: data.exteriorSitePlans,
    grocery_sites: [
      {
        report_title: data.reportTitle,
        state: 'TX',
        total_area_sqft:
          data.areaType && data?.areaType[0]?.label == 'Acre'
            ? Number(data?.totalAreaSqFt * 43560)
            : data.totalAreaSqFt,
        address: data.compAddress,
        lat: data.lat,
        lng: data.lon,
        num_curb_cuts: data.totalCurbCuts,
        avg_curb_cut_size: data.avgCurbCutSize,
        frontage_footage_pri: data.priFrontFootage,
        frontage_footage_sec: data.secFrontFootage,
        is_functioning_retail: data.isFunRetailUnit,
        num_cooler: data.f_cooler_door,
        cstoresalespermonth: data.cStoreSalePerMonth,
        num_freezer: data.f_freezer_door,
        has_beer_cave: data.f_beer_cave,
        is_24hour: data.f_24H_ops,
      },
    ],
  };

  return obj;
};

export function getMonthName(monthNumber) {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  // Validate the input
  if (monthNumber >= 1 && monthNumber <= 12) {
    return months[monthNumber - 1];
  } else {
    return 'Invalid month number';
  }
}
